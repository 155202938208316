import React, { Component } from 'react';
import Navbar from './Navbar';
import ChordSearch from './ChordSearch';
import { VoicingSet, Settings, TuningId } from '../gen/proto/chordgen_pb';
import { Loading, loadingPromise } from '../Loading';
import { fetchVoicings, ApiError } from '../api';
import VoicingResults from './VoicingResults';
import HelpPage from './HelpPage';
import LoadingIndicator from './LoadingIndicator';
import ErrorMessage from './ErrorMessage';
import parseChordAndSettings from '../util/SettingsParser';

const initialState = {
  results: Loading.empty() as Loading<VoicingSet>,
  input: '',
};

type State = Readonly<typeof initialState>;
type Props = Object;

export default class MainPage extends Component<Props, State>  {
  readonly state = initialState;

  render() {
    let result = null;
    if (this.state.results.isLoading()) {
      result = <LoadingIndicator />;
    } else if (this.state.results.loaded()) {
      result = <VoicingResults voicings={this.state.results.content()} />;
    } else if (this.state.results.hasError()) {
      const error = this.state.results.error();
      result = <ErrorMessage error={error} />;
    } else {
      result = <HelpPage />;
    }
    return (
      <div>
        <ChordSearch onSubmit={chordName => this.submit(chordName)} />
        {result}
      </div>
    );
  }

  private submit(input: string) {
    if (this.state.input === input) {
      return;
    }
    if (!input) {
      this.setState({
        results: Loading.empty()
      });
      return;
    }

    this.setState({ input });
    try {
      const parsed = parseChordAndSettings(input);
      loadingPromise(fetchVoicings(parsed.chordName, parsed.settings), results => {
        this.setState({
          results,
        });
      });
    } catch (exception) {
      this.setState({
        results: Loading.completeError(exception),
      });
    }
  }
}
