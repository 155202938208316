interface LoadingType<T> {
  content?: T,
  error?: Error,
  loading: boolean,
}

export function loadingPromise<T>(promise: Promise<T>, callback: (item: Loading<T>) => void) {
  callback(Loading.loading());
  promise.then(value => callback(Loading.complete(value)))
    .catch(error => callback(Loading.completeError(error)));
}

export class Loading<T> {
  private _content = null as T | null;
  private _error = null as Error | null;
  private _loading = false;

  private constructor(loading: LoadingType<T>) {
    this._loading = loading.loading;
    if (loading.content != null) {
      this._content = loading.content;
    }
    if (loading.error != null) {
      this._error = loading.error;
    }
  }

  static empty<T>(): Loading<T> {
    return new Loading<T>({ loading: false });
  }
  static loading<T>(): Loading<T> {
    return new Loading<T>({ loading: true });
  }
  static complete<T>(value: T): Loading<T> {
    return new Loading<T>({ loading: false, content: value });
  }
  static completeError<T>(error: Error): Loading<T> {
    return new Loading<T>({ loading: false, error });
  }

  isLoading() {
    return this._loading;
  }

  loaded() {
    return !this._loading && this._content != null;
  }
  content() {
    if (this._content == null) {
      throw Error("called content() on a Loading<T> with no value");
    }
    return this._content;
  }

  hasError() {
    return !this._loading && this._error != null;
  }
  error() {
    if (this._error == null) {
      throw Error("called error() on a Loading<T> with no error");
    }
    return this._error;
  }
}