import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Navbar from './components/Navbar';
import MainPage from './components/MainPage';
import HelpPage from './components/HelpPage';
import AboutPage from './components/AboutPage';

import './css/App.css';

export default function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <div id="app">
          <Route exact path="/" component={MainPage} />
          <Route path="/help" component={HelpPage} />
          <Route path="/about" component={AboutPage} />
        </div>
      </div>
    </Router>
  );
}