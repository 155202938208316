import React from 'react';

import '../css/HelpPage.css';
import { Chord, Note, GuitarString, PlayedNote, Tuning, TuningId, Interval } from '../gen/proto/chordgen_pb';
import VoicingChart from './VoicingChart';


function ExampleVoicingChart(props: {}) {
  function makeNote(name: string, interval?: number) {
    const note = new Note();
    note.setName(name);
    if (interval != null) {
      const intv = new Interval();
      intv.setRange(interval);
      note.setInterval(intv);
    }
    return note;
  }
  function makeString(name: string, index: number) {
    const string = new GuitarString();
    string.setName(name);
    string.setIndex(index)
    return string;
  }
  function makePlayedNote(guitarString: GuitarString, note: string, fret: number, finger: number) {
    const played = new PlayedNote();
    played.setFinger(finger);
    played.setFret(fret);
    played.setString(guitarString);
    played.setNote(makeNote(note));
    return played;
  }

  const dMajChord = new Chord();
  dMajChord.setName("D");
  dMajChord.setNotesList([
    makeNote("D", 1),
    makeNote("F#", 3),
    makeNote("A", 5)
  ]);
  const tuning = new Tuning();
  tuning.setId(TuningId.STANDARD);
  tuning.setStringsList([
    makeString("E", 0),
    makeString("A", 1),
    makeString("D", 2),
    makeString("G", 3),
    makeString("B", 4),
    makeString("e", 5),
  ])

  const notes = [
    makePlayedNote(makeString("D", 2), "D", 0, 0),
    makePlayedNote(makeString("G", 3), "A", 2, 1),
    makePlayedNote(makeString("B", 4), "D", 3, 2),
    makePlayedNote(makeString("e", 5), "F#", 2, 1),
  ];
  return <VoicingChart chord={dMajChord} notes={notes} tuning={tuning} stringsPlaying={[]} />;
}

export default function HelpPage(props: {}) {
  return (
    <div className="help-page">
      <h3 className="help-question">How do I use this thing?</h3>
      <div className="help-answer">
        <p>
          Type in the name of a chord into the big textbox. Diagrams will come out below that
          show some <i>suggestions</i> of how to play it.
        </p>
        <p>
          The system is fairly rigid in how it understands the names of the chords. It breaks
          down into a few parts:
        </p>
        <ul>
          <li>
            The note name, starting with an uppercase letter (A - G), like
            {' '}<span className="chord-example">C</span>. Optionally, add flats
            or sharps as <code>b</code> / <code>#</code> as in
            {' '}<span className="chord-example">Bb</span> or
            {' '}<span className="chord-example">F#</span>.
          </li>
          <li>
            The flavor of chord. By default you'll get a major chord if you type in
            something like <span className="chord-example">G</span>. For minor,
            use a small <code>m</code> like
            {' '}<span className="chord-example">Gm</span>. Diminished and augmented
            are also possible as in
            {' '}<span className="chord-example">Gdim</span> or
            {' '}<span className="chord-example">Gaug</span>/<span className="chord-example">G+</span>.
          </li>
          <li>
            Any number of chord extensions, like a seventh or a flat-nine. You can combine
            these by just adding on to the end. Just check some examples out. Not all crazy
            flavors you can think of are supported yet.
            <ul>
              <li><span className="chord-example">F7</span>: F dominant seven</li>
              <li><span className="chord-example">EbM7</span>: Eb major seven</li>
              <li><span className="chord-example">A11b9</span>: A11 with a flat nine</li>
              <li>
                <span className="chord-example">Bflat9</span>:
                B dominant with a flat nine, <code>flat9</code> can be used to distinguish
                from Bb-9.
                </li>
            </ul>
          </li>
          <li>
            Optionally, choose a bass note for the chord, which does not have to be in the chord.
            This uses the typical <code>/</code> notation. For example,
            {' '}<span className="chord-example">G/F#</span>.
          </li>
        </ul>
      </div>
      <h3 className="help-question">Ok, but how do I read the diagrams?</h3>
      <div className="help-answer">
        <p>
          Start with an example of <span className="chord-example">D</span> major in open position here:
        </p>
        <ExampleVoicingChart />
        <p>
          There's a lot going on so lets break it down. On the left side are the string
          labels, they just show the names of the strings in typical standard tuning. The
          lower pitched, larger strings (the ones closer to your face if you're holding a
          guitar like a normal person) are on the bottom. Higher pitched strings are on top.
        </p>
        <p>
          On the bottom, you've got labels for frets. The label comes before the line for that fret
          when reading left-to-right. This chord is shown in open position (so the far left line is
          the top of the fretboard), but others may not be. Open position is specially denoted with the
          two vertical lines you see there on the left. There are dots below certain fret numbers (like 3, 5, 7)
          that match the typical dot markings you see on fretboards so you can match it up.
        </p>
        <p>
          The bulk of the diagram represents a fretboard, as if you laid a guitar down flat (and
          made the frets uniform distance for some reason).
          Horizontal lines represent the strings, as labeled and described above. Vertical lines
          are frets.
        </p>
        <p>
          On the fretboard, there will be circles and curved rectangles. These are where you put your fingers.
          For example, you'll see a circle with <code>2</code> on the line for the <code>B</code> string.
          This means you use your second finger (your middle finger) on the <code>B</code> string behind
          the third fret. The curved rectangle with a <code>1</code> tells you to bar the second fret
          with your first finger (your pointer finger), placing it over the top 3 strings.
          Lastly, you'll notice a small open circle on the left
          of the fretboard on the <code>D</code> string, which tells you to play that string but not fret
          anything (leave open). The border colors correspond to the notes of the chord. There's
          a legend at the top.
        </p>
        <p>
          Any strings with no markings (no fret circle nor open circle) aren't supposed to be played.
          In some cases this may require you to skip strings in the middle, either picking the
          strings individually (or with separate fingers) or muting them. This app won't show you
          how to do that though.
        </p>
      </div>
      <h3 className="help-question">What if I can't play them?</h3>
      <div className="help-answer">
        <p>
          The fingerings listed are suggestions generated by an algorithm. The algorithm
          tries its best, but isn't particularly great. It lists the suggestions in
          order of "goodness" (by some definition), so try scrolling down a few
          if you can't play the first one or if the first one sucks.
        </p>
      </div>
      <h3 className="help-question">Why do the suggestions suck?</h3>
      <div className="help-answer">
        <p>
          The algorithm that generates them isn't great. Maybe they'll get better.
        </p>
      </div>
      <h3 className="help-question">How can I get this as a mobile app?</h3>
      <div className="help-answer">
        <p>
          This is one of those fancy web apps that works kinda like a mobile app.
          If you're on Android, it should've prompted you to add it to your home screen
          when you first loaded. If you didn't then, you should see "Add to Home Screen"
          in the top-right 3-dot menu.
        </p>
        <p>
          On iOS, "Add to Home Screen" is in the share options of Safari, in a similar top-right
          menu location.
        </p>
        <p>
          Note that not all things will work offline as some information may not be downloaded
          on your local device yet.
        </p>
        <p>
          If you decide you hate the app, you can uninstall it like any other app.
        </p>
      </div>
    </div>
  );
}