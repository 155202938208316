import React from 'react';
import '../css/LoadingIndicator.css';

export default function () {
  return (
    <div className="loading-indicator">
      <svg width="200" height="200">
        <circle cx="50%" cy="50%" r="90" className="loading-indicator-circle" />
        <circle cx="50%" cy="50%" r="90" className="loading-indicator-dot" />
        <text x="50%" y="50%" className="loading-indicator-text">Thinking</text>
      </svg>
    </div>
  );
}