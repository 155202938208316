import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../css/Navbar.css';

export default class Navbar extends Component {
  render() {
    return (
      <div id="navbar-top">
        <div id="navbar-outer">
          <div id="navbar">
              <Link className="navbar-item button" to="/">Chordgen</Link>
            <span id="navbar-right">
              <Link className="navbar-item button" to="/about">About</Link>
              <Link className="navbar-item button" to="/help">Help</Link>
            </span>
          </div>
        </div>
      </div>
    );
  }
}