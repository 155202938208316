import React from 'react';

import '../css/HelpPage.css';


export default function AboutPage(props: {}) {
  return (
    <div className="help-page">
      <h3 className="help-question">What is this thing?</h3>
      <div className="help-answer">
        <p>
          It's an app that generates diagrams for playing various chords
          on the guitar and shows them to you. If you don't understand how
          to use it, click "Help" instead.
        </p>
      </div>
      <h3 className="help-question">How does it work?</h3>
      <div className="help-answer">
        <p>
          Everything is algorithmically generated. The chord name is parsed,
          broken down into the specific notes (e.g. "C" into C, E, and G), then
          some code tries to place fingers on a fretboard to make those notes and
          ranks the results.
        </p>
        <p>
          The ranking is based on a few heuristics. It considers things like
          distinct notes (so you don't get a "chord" with just C's), how far
          apart fingers are (so it's possible to play), placement of intervals,
          and other music theory nonsense. They're not perfect and they're tuned
          on my imperfect experience, so results may vary.
        </p>
      </div>
      <h3 className="help-question">More technical details!</h3>
      <div className="help-answer">
        <p>
          This is a somewhat typical "Progressive Web App", meaning a browser
          app that uses caching tricks to also work offline. You can "install"
          it onto a mobile devices home screen too (see Help page). If the
          cache hasn't been populated yet it won't work offline.
        </p>
        <p>
          The app communicates with a server that does the heavy lifting and music
          theory understanding. It sends just enough information so the app
          can make the pretty charts and diagrams. The server is a pretty standard
          Kotlin HTTP server running on the JVM. HTTP responses are encoded using protobuf.
        </p>
        <p>
          The client side of the app is mostly plain React (with react-router, etc) 
          using TypeScript. Diagrams are dynamically rendered as SVG with a fair bit 
          of hackery to make them size responsive (and because I'm bad with SVGs).
        </p>
      </div>
      <h3 className="help-question">What about the guitar sounds?</h3>
      <div className="help-answer">
        <p>
          They're generated using the Karplus-Strong
          algorithm with a few tweaks to make it sound good over the guitar's range.
        </p>
      </div>
    </div>
  );
}