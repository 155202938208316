import * as proto from './gen/proto/chordgen_pb';

const BASE_URL = 'https://api.chordgen.xyz';

export class ApiError extends Error { }

export class DeserializationError extends Error { }

function buildUrl(path: string, params = {} as Record<string, string>): string {
  const url = new URL(BASE_URL + path);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  return url.toString();
}

async function getContent(response: Response): Promise<Uint8Array> {
  const body = await response.arrayBuffer();
  if (response.status >= 400) {
    const error = proto.Error.deserializeBinary(new Uint8Array(body));
    throw new ApiError(error.getMessage());
  }
  return new Uint8Array(body);
}

function base64Encode(buffer: Uint8Array) {
  const decoder = new TextDecoder('utf8');
  return btoa(decoder.decode(buffer));
}

export async function fetchVoicings(
  chordName: string,
  settings: proto.Settings
): Promise<proto.VoicingSet> {
  const params = {
    name: chordName,
    settings: base64Encode(settings.serializeBinary()),
  }
  const response = await fetch(buildUrl('/voicings/', params));
  const body = await getContent(response);
  try {
    return proto.VoicingSet.deserializeBinary(body);
  } catch (error) {
    throw new DeserializationError(error);
  }
}
