import React from 'react';
import { ApiError, DeserializationError } from '../api';
import { ParseError } from '../util/SettingsParser';

import '../css/ErrorMessage.css';

function ErrorX(props: {}) {
  return (
    <svg className="error-message-x">
      <line
        x1="10%"
        y1="10%"
        x2="90%"
        y2="90%"
        className="error-message-x-line"
      />
      <line
        x1="90%"
        y1="10%"
        x2="10%"
        y2="90%"
        className="error-message-x-line"
      />
    </svg>
  );
}

function ErrorDescription({ error }: Props) {
  if (error instanceof ApiError) {
    // bad input usually
    return (
      <div className="error-message-desc">
        <p>
          The app can't really understand what you've typed in. The
          robots say: <code>{error.message}</code>.
        </p>
        <p>
          If you're having trouble understanding what to type in, try
          the "Help" button at the top.
        </p>
      </div>
    );
  } else if (error instanceof ParseError) {
    return (
      <div className="error-message-desc">
        <p>
          The app got part of what you were saying, but lost you along the way.
          It says: <code>{error.message}</code>
        </p>
        <p>
          If you're having trouble understanding what to type in, try
          the "Help" button at the top.
        </p>
      </div>
    );
  } else if (error instanceof DeserializationError) {
    console.error(error);
    return (
      <div className="error-message-desc">
        <p>
          Wow. Something is really wrong. What you typed in is probably
          valid but app just doesn't understand what's going on.
        </p>
        <p>
          Try closing or refreshing the app, and make sure to close other tabs
          if you have them open. It could be your version is too outdated.
        </p>
      </div>
    )
  } else {
    console.error(error);
    return (
      <div className="error-message-desc">
        <p>
          Couldn't contact the server to understand the music theory parts
          of what's going on. It's possible you're offline, or the music
          theory server is currently on fire.
        </p>
        <p>
          Some data is downloaded and available offline but what you typed in
          is apparently not yet available.
        </p>
      </div>
    );
  }
}

interface Props {
  error: Error,
}
export default function ErrorMessage({ error }: Props) {
  return (
    <div className="error-message">
      <h2>Oh no!</h2>
      <ErrorX />
      <ErrorDescription error={error} />
    </div>
  )
}