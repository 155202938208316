import { PlayedNote } from "../gen/proto/chordgen_pb";
import nullthrows from "../util/nullthrows";

const VOLUME_MODIFIER = 0.40;

let context: AudioContext | null = null;

function getContext() {
  if (context != null) {
    return context;
  }
  const wind = (<any>window);
  if (wind.AudioContext) {
    context = new AudioContext();
  } else if (wind.webkitAudioContext) {
    // AudioContext is relatively new, hack in for older
    // versions and Safari
    context = new wind.webkitAudioContext();
  } else {
    alert("Sorry, the Audio API isn't supported in your browser");
    throw new Error("Unsupported browser AudioContext");
  }
  return nullthrows(context);
}

class BufferWriter {
  private offset = 0
  public readonly buffer: Float32Array

  constructor(buffer: Float32Array, offset = 0) {
    this.buffer = buffer
    this.offset = offset
  }

  add(sample: number) {
    this.buffer[this.offset] += sample
    // Clamp into range
    if (this.buffer[this.offset] <= -1.0) {
      this.buffer[this.offset] = -1.0;
    } else if (this.buffer[this.offset] >= 1.0) {
      this.buffer[this.offset] = 1.0;
    }

    this.offset++;
  }
  delayed(delaySeconds: number) {
    const sampleRate = getContext().sampleRate;
    return new BufferWriter(
      this.buffer, this.offset + Math.round(sampleRate * delaySeconds));
  }
  full() { 
    return this.offset == this.buffer.length;
  }
  clone() {
    return new BufferWriter(this.buffer, this.offset);
  }
}

function playAudio(buffer: Float32Array) {
  const context = getContext();
  const channels = context.createBuffer(1, buffer.length, context.sampleRate);
  const channelData = channels.getChannelData(0);
  for (let i = 0; i < buffer.length; i++) {
    channelData[i] = buffer[i];
  }

  const source = context.createBufferSource();
  source.buffer = channels;
  source.connect(context.destination);
  source.start();
}

function getDecayFactor(frequency: number) {
  // Linear fit based on (100, 0.99), (400, 1.0)
  const computed = 0.990667 + 0.0000233333 * frequency;
  const eps = 1e-4;
  return Math.min(1.0 - eps, computed);
}

function getDecayWeight(frequency: number) {
  // Linear fit based on (100, 0.5), (600, 0.7)
  const computed = 0.46 + 0.0004 * frequency;
  return Math.min(0.7, Math.max(0.5, computed));
}

function synthesizeString(frequency: number, writer: BufferWriter) {
  const sampleRate = getContext().sampleRate;

  const noiseSamples = Math.round(sampleRate / frequency - 0.5);
  const noise = [];
  for (let i = 0; i < noiseSamples; i++) {
    const random = 2 * Math.round(Math.random()) - 1;
    noise[i] = VOLUME_MODIFIER * random;
  }

  let prev = 0;
  const decayFactor = getDecayFactor(frequency);
  const decayWeight = getDecayWeight(frequency);
  for (let i = 0; !writer.full(); i++) {
    const noiseIdx = i % noiseSamples;
    const sample: number = decayFactor * (
      decayWeight * noise[noiseIdx] + (1 - decayWeight) * prev
    );
    noise[noiseIdx] = sample;

    writer.add(sample)
    prev = sample;
  }
}

function delay(samples: number[], seconds: number) {
  const sampleRate = getContext().sampleRate;

  const delaySamples = seconds * sampleRate;
  const arr = [];
  for (let i = 0; i < delaySamples; i++) {
    arr.push(0);
  }
  for (const sample of samples) {
    arr.push(sample);
  }
  return arr;
}

function fadeOut(buffer: Float32Array, fadeSeconds: number) {
  const sampleRate = getContext().sampleRate;
  const fadeSamples = fadeSeconds * sampleRate;
  for (let i = 0; i < fadeSamples && i < buffer.length; i++) {
    const fadeFactor = i / fadeSamples;
    buffer[buffer.length - 1 - i] *= fadeFactor;
  }
}

const CHORD_SYNTH_SECONDS = 4.0;
const ARPEGGIO_OFFSET_SECONDS = 0.2;
function buildArpeggio(notes: PlayedNote[], writer: BufferWriter) {
  for (let i = 0; i < notes.length; i++) {
    const delaySeconds = i * ARPEGGIO_OFFSET_SECONDS;
    synthesizeString(notes[i].getPitch(), writer.delayed(delaySeconds));
  }
}

const STRUM_DELAY_SECONDS = 0.7;
const STRUM_OFFSET_SECONDS = 0.03;
function buildStrum(notes: PlayedNote[], writer: BufferWriter) {
  for (let i = 0; i < notes.length; i++) {
    const delaySeconds = STRUM_DELAY_SECONDS + STRUM_OFFSET_SECONDS * i;
    synthesizeString(notes[i].getPitch(), writer.delayed(delaySeconds));
  }
}

const FADE_OUT_SECONDS = 0.5;
export function playChord(
  playedNotes: PlayedNote[],
  pluckCallback?: (note: PlayedNote) => void,
) {
  // Make a copy of notes to avoid modifying params
  const notes = playedNotes.slice();
  notes.sort(
    (a, b) =>
      nullthrows(a.getString()).getIndex() - nullthrows(b.getString()).getIndex()
  );
  const sampleRate = getContext().sampleRate;
  const buffer = new Float32Array(Math.round(CHORD_SYNTH_SECONDS * sampleRate));
  const writer = new BufferWriter(buffer);
  buildArpeggio(notes, writer.clone());
  buildStrum(notes, writer.delayed(notes.length * ARPEGGIO_OFFSET_SECONDS));
  fadeOut(buffer, FADE_OUT_SECONDS);

  playAudio(buffer);

  // Invoke callbacks for all notes during the arpeggio phase
  if (pluckCallback != null) {
    for (let i = 0; i < notes.length; i++) {
      setTimeout(() => pluckCallback(notes[i]), 1000 * i * ARPEGGIO_OFFSET_SECONDS);
    }
    for (let i = 0; i < notes.length; i++) {
      const strumDelay = (
        STRUM_DELAY_SECONDS + STRUM_OFFSET_SECONDS * i +
        notes.length * ARPEGGIO_OFFSET_SECONDS
      );
      setTimeout(() => pluckCallback(notes[i]), 1000 * strumDelay);
    }
  }
}
