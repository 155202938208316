/**
 * @fileoverview
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.chordgen.AllChordVoicings', null, global);
goog.exportSymbol('proto.chordgen.Chord', null, global);
goog.exportSymbol('proto.chordgen.ChordFlavor', null, global);
goog.exportSymbol('proto.chordgen.Error', null, global);
goog.exportSymbol('proto.chordgen.ErrorCode', null, global);
goog.exportSymbol('proto.chordgen.GuitarString', null, global);
goog.exportSymbol('proto.chordgen.Interval', null, global);
goog.exportSymbol('proto.chordgen.Note', null, global);
goog.exportSymbol('proto.chordgen.NoteList', null, global);
goog.exportSymbol('proto.chordgen.NoteType', null, global);
goog.exportSymbol('proto.chordgen.PlayedNote', null, global);
goog.exportSymbol('proto.chordgen.ScoringInformation', null, global);
goog.exportSymbol('proto.chordgen.Settings', null, global);
goog.exportSymbol('proto.chordgen.Tuning', null, global);
goog.exportSymbol('proto.chordgen.TuningId', null, global);
goog.exportSymbol('proto.chordgen.Voicing', null, global);
goog.exportSymbol('proto.chordgen.VoicingSet', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.Interval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chordgen.Interval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.Interval.displayName = 'proto.chordgen.Interval';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.Interval.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.Interval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.Interval} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.Interval.toObject = function(includeInstance, msg) {
  var f, obj = {
    range: msg.getRange(),
    sharps: msg.getSharps(),
    flats: msg.getFlats()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.Interval}
 */
proto.chordgen.Interval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.Interval;
  return proto.chordgen.Interval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.Interval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.Interval}
 */
proto.chordgen.Interval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRange(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSharps(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.Interval} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Interval.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.Interval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Interval.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getRange();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = this.getSharps();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getFlats();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.Interval} The clone.
 */
proto.chordgen.Interval.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.Interval} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional int32 range = 1;
 * @return {number}
 */
proto.chordgen.Interval.prototype.getRange = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 1, 0));
};


/** @param {number} value  */
proto.chordgen.Interval.prototype.setRange = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 sharps = 2;
 * @return {number}
 */
proto.chordgen.Interval.prototype.getSharps = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 2, 0));
};


/** @param {number} value  */
proto.chordgen.Interval.prototype.setSharps = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 flats = 3;
 * @return {number}
 */
proto.chordgen.Interval.prototype.getFlats = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 3, 0));
};


/** @param {number} value  */
proto.chordgen.Interval.prototype.setFlats = function(value) {
  jspb.Message.setField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.Note = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chordgen.Note, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.Note.displayName = 'proto.chordgen.Note';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.Note.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.Note.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.Note} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.Note.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: msg.getName(),
    type: msg.getType(),
    interval: (f = msg.getInterval()) && proto.chordgen.Interval.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.Note}
 */
proto.chordgen.Note.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.Note;
  return proto.chordgen.Note.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.Note}
 */
proto.chordgen.Note.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {!proto.chordgen.NoteType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = new proto.chordgen.Interval;
      reader.readMessage(value,proto.chordgen.Interval.deserializeBinaryFromReader);
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.Note} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Note.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.Note.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Note.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = this.getInterval();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chordgen.Interval.serializeBinaryToWriter
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.Note} The clone.
 */
proto.chordgen.Note.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.Note} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.chordgen.Note.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldProto3(this, 1, ""));
};


/** @param {string} value  */
proto.chordgen.Note.prototype.setName = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional NoteType type = 2;
 * @return {!proto.chordgen.NoteType}
 */
proto.chordgen.Note.prototype.getType = function() {
  return /** @type {!proto.chordgen.NoteType} */ (jspb.Message.getFieldProto3(this, 2, 0));
};


/** @param {!proto.chordgen.NoteType} value  */
proto.chordgen.Note.prototype.setType = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional Interval interval = 3;
 * @return {proto.chordgen.Interval}
 */
proto.chordgen.Note.prototype.getInterval = function() {
  return /** @type{proto.chordgen.Interval} */ (
    jspb.Message.getWrapperField(this, proto.chordgen.Interval, 3));
};


/** @param {proto.chordgen.Interval|undefined} value  */
proto.chordgen.Note.prototype.setInterval = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.chordgen.Note.prototype.clearInterval = function() {
  this.setInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return{!boolean}
 */
proto.chordgen.Note.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.PlayedNote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chordgen.PlayedNote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.PlayedNote.displayName = 'proto.chordgen.PlayedNote';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.PlayedNote.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.PlayedNote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.PlayedNote} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.PlayedNote.toObject = function(includeInstance, msg) {
  var f, obj = {
    note: (f = msg.getNote()) && proto.chordgen.Note.toObject(includeInstance, f),
    string: (f = msg.getString()) && proto.chordgen.GuitarString.toObject(includeInstance, f),
    fret: msg.getFret(),
    finger: msg.getFinger(),
    pitch: msg.getPitch()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.PlayedNote}
 */
proto.chordgen.PlayedNote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.PlayedNote;
  return proto.chordgen.PlayedNote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.PlayedNote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.PlayedNote}
 */
proto.chordgen.PlayedNote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chordgen.Note;
      reader.readMessage(value,proto.chordgen.Note.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 2:
      var value = new proto.chordgen.GuitarString;
      reader.readMessage(value,proto.chordgen.GuitarString.deserializeBinaryFromReader);
      msg.setString(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFret(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinger(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPitch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.PlayedNote} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.PlayedNote.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.PlayedNote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.PlayedNote.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getNote();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chordgen.Note.serializeBinaryToWriter
    );
  }
  f = this.getString();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.chordgen.GuitarString.serializeBinaryToWriter
    );
  }
  f = this.getFret();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getFinger();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getPitch();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.PlayedNote} The clone.
 */
proto.chordgen.PlayedNote.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.PlayedNote} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional Note note = 1;
 * @return {proto.chordgen.Note}
 */
proto.chordgen.PlayedNote.prototype.getNote = function() {
  return /** @type{proto.chordgen.Note} */ (
    jspb.Message.getWrapperField(this, proto.chordgen.Note, 1));
};


/** @param {proto.chordgen.Note|undefined} value  */
proto.chordgen.PlayedNote.prototype.setNote = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.chordgen.PlayedNote.prototype.clearNote = function() {
  this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return{!boolean}
 */
proto.chordgen.PlayedNote.prototype.hasNote = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GuitarString string = 2;
 * @return {proto.chordgen.GuitarString}
 */
proto.chordgen.PlayedNote.prototype.getString = function() {
  return /** @type{proto.chordgen.GuitarString} */ (
    jspb.Message.getWrapperField(this, proto.chordgen.GuitarString, 2));
};


/** @param {proto.chordgen.GuitarString|undefined} value  */
proto.chordgen.PlayedNote.prototype.setString = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.chordgen.PlayedNote.prototype.clearString = function() {
  this.setString(undefined);
};


/**
 * Returns whether this field is set.
 * @return{!boolean}
 */
proto.chordgen.PlayedNote.prototype.hasString = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 fret = 3;
 * @return {number}
 */
proto.chordgen.PlayedNote.prototype.getFret = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 3, 0));
};


/** @param {number} value  */
proto.chordgen.PlayedNote.prototype.setFret = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 finger = 4;
 * @return {number}
 */
proto.chordgen.PlayedNote.prototype.getFinger = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 4, 0));
};


/** @param {number} value  */
proto.chordgen.PlayedNote.prototype.setFinger = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional double pitch = 5;
 * @return {number}
 */
proto.chordgen.PlayedNote.prototype.getPitch = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 5, 0));
};


/** @param {number} value  */
proto.chordgen.PlayedNote.prototype.setPitch = function(value) {
  jspb.Message.setField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.Chord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chordgen.Chord.repeatedFields_, null);
};
goog.inherits(proto.chordgen.Chord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.Chord.displayName = 'proto.chordgen.Chord';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chordgen.Chord.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.Chord.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.Chord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.Chord} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.Chord.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: msg.getName(),
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto.chordgen.Note.toObject, includeInstance),
    flavorsList: jspb.Message.getField(msg, 3)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.Chord}
 */
proto.chordgen.Chord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.Chord;
  return proto.chordgen.Chord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.Chord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.Chord}
 */
proto.chordgen.Chord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.chordgen.Note;
      reader.readMessage(value,proto.chordgen.Note.deserializeBinaryFromReader);
      msg.getNotesList().push(value);
      msg.setNotesList(msg.getNotesList());
      break;
    case 3:
      var value = /** @type {!Array.<!proto.chordgen.ChordFlavor>} */ (reader.readPackedEnum());
      msg.setFlavorsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.Chord} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Chord.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.Chord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Chord.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.chordgen.Note.serializeBinaryToWriter
    );
  }
  f = this.getFlavorsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.Chord} The clone.
 */
proto.chordgen.Chord.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.Chord} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.chordgen.Chord.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldProto3(this, 1, ""));
};


/** @param {string} value  */
proto.chordgen.Chord.prototype.setName = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * repeated Note notes = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.chordgen.Note>}
 */
proto.chordgen.Chord.prototype.getNotesList = function() {
  return /** @type{!Array.<!proto.chordgen.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chordgen.Note, 2));
};


/** @param {Array.<!proto.chordgen.Note>} value  */
proto.chordgen.Chord.prototype.setNotesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


proto.chordgen.Chord.prototype.clearNotesList = function() {
  this.setNotesList([]);
};


/**
 * repeated ChordFlavor flavors = 3;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.chordgen.ChordFlavor>}
 */
proto.chordgen.Chord.prototype.getFlavorsList = function() {
  return /** @type {!Array.<!proto.chordgen.ChordFlavor>} */ (jspb.Message.getField(this, 3));
};


/** @param {!Array.<!proto.chordgen.ChordFlavor>} value  */
proto.chordgen.Chord.prototype.setFlavorsList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


proto.chordgen.Chord.prototype.clearFlavorsList = function() {
  jspb.Message.setField(this, 3, []);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.GuitarString = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chordgen.GuitarString, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.GuitarString.displayName = 'proto.chordgen.GuitarString';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.GuitarString.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.GuitarString.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.GuitarString} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.GuitarString.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: msg.getName(),
    index: msg.getIndex()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.GuitarString}
 */
proto.chordgen.GuitarString.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.GuitarString;
  return proto.chordgen.GuitarString.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.GuitarString} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.GuitarString}
 */
proto.chordgen.GuitarString.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.GuitarString} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.GuitarString.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.GuitarString.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.GuitarString.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.GuitarString} The clone.
 */
proto.chordgen.GuitarString.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.GuitarString} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.chordgen.GuitarString.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldProto3(this, 1, ""));
};


/** @param {string} value  */
proto.chordgen.GuitarString.prototype.setName = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 index = 2;
 * @return {number}
 */
proto.chordgen.GuitarString.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 2, 0));
};


/** @param {number} value  */
proto.chordgen.GuitarString.prototype.setIndex = function(value) {
  jspb.Message.setField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.Tuning = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chordgen.Tuning.repeatedFields_, null);
};
goog.inherits(proto.chordgen.Tuning, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.Tuning.displayName = 'proto.chordgen.Tuning';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chordgen.Tuning.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.Tuning.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.Tuning.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.Tuning} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.Tuning.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: msg.getId(),
    capo: msg.getCapo(),
    stringsList: jspb.Message.toObjectList(msg.getStringsList(),
    proto.chordgen.GuitarString.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.Tuning}
 */
proto.chordgen.Tuning.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.Tuning;
  return proto.chordgen.Tuning.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.Tuning} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.Tuning}
 */
proto.chordgen.Tuning.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chordgen.TuningId} */ (reader.readEnum());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCapo(value);
      break;
    case 3:
      var value = new proto.chordgen.GuitarString;
      reader.readMessage(value,proto.chordgen.GuitarString.deserializeBinaryFromReader);
      msg.getStringsList().push(value);
      msg.setStringsList(msg.getStringsList());
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.Tuning} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Tuning.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.Tuning.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Tuning.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = this.getCapo();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getStringsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.chordgen.GuitarString.serializeBinaryToWriter
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.Tuning} The clone.
 */
proto.chordgen.Tuning.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.Tuning} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional TuningId id = 1;
 * @return {!proto.chordgen.TuningId}
 */
proto.chordgen.Tuning.prototype.getId = function() {
  return /** @type {!proto.chordgen.TuningId} */ (jspb.Message.getFieldProto3(this, 1, 0));
};


/** @param {!proto.chordgen.TuningId} value  */
proto.chordgen.Tuning.prototype.setId = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 capo = 2;
 * @return {number}
 */
proto.chordgen.Tuning.prototype.getCapo = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 2, 0));
};


/** @param {number} value  */
proto.chordgen.Tuning.prototype.setCapo = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * repeated GuitarString strings = 3;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.chordgen.GuitarString>}
 */
proto.chordgen.Tuning.prototype.getStringsList = function() {
  return /** @type{!Array.<!proto.chordgen.GuitarString>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chordgen.GuitarString, 3));
};


/** @param {Array.<!proto.chordgen.GuitarString>} value  */
proto.chordgen.Tuning.prototype.setStringsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


proto.chordgen.Tuning.prototype.clearStringsList = function() {
  this.setStringsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.Voicing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chordgen.Voicing.repeatedFields_, null);
};
goog.inherits(proto.chordgen.Voicing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.Voicing.displayName = 'proto.chordgen.Voicing';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chordgen.Voicing.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.Voicing.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.Voicing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.Voicing} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.Voicing.toObject = function(includeInstance, msg) {
  var f, obj = {
    chord: (f = msg.getChord()) && proto.chordgen.Chord.toObject(includeInstance, f),
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto.chordgen.PlayedNote.toObject, includeInstance),
    tuning: (f = msg.getTuning()) && proto.chordgen.Tuning.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.Voicing}
 */
proto.chordgen.Voicing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.Voicing;
  return proto.chordgen.Voicing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.Voicing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.Voicing}
 */
proto.chordgen.Voicing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chordgen.Chord;
      reader.readMessage(value,proto.chordgen.Chord.deserializeBinaryFromReader);
      msg.setChord(value);
      break;
    case 2:
      var value = new proto.chordgen.PlayedNote;
      reader.readMessage(value,proto.chordgen.PlayedNote.deserializeBinaryFromReader);
      msg.getNotesList().push(value);
      msg.setNotesList(msg.getNotesList());
      break;
    case 3:
      var value = new proto.chordgen.Tuning;
      reader.readMessage(value,proto.chordgen.Tuning.deserializeBinaryFromReader);
      msg.setTuning(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.Voicing} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Voicing.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.Voicing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Voicing.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getChord();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chordgen.Chord.serializeBinaryToWriter
    );
  }
  f = this.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.chordgen.PlayedNote.serializeBinaryToWriter
    );
  }
  f = this.getTuning();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.chordgen.Tuning.serializeBinaryToWriter
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.Voicing} The clone.
 */
proto.chordgen.Voicing.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.Voicing} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional Chord chord = 1;
 * @return {proto.chordgen.Chord}
 */
proto.chordgen.Voicing.prototype.getChord = function() {
  return /** @type{proto.chordgen.Chord} */ (
    jspb.Message.getWrapperField(this, proto.chordgen.Chord, 1));
};


/** @param {proto.chordgen.Chord|undefined} value  */
proto.chordgen.Voicing.prototype.setChord = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.chordgen.Voicing.prototype.clearChord = function() {
  this.setChord(undefined);
};


/**
 * Returns whether this field is set.
 * @return{!boolean}
 */
proto.chordgen.Voicing.prototype.hasChord = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated PlayedNote notes = 2;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.chordgen.PlayedNote>}
 */
proto.chordgen.Voicing.prototype.getNotesList = function() {
  return /** @type{!Array.<!proto.chordgen.PlayedNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chordgen.PlayedNote, 2));
};


/** @param {Array.<!proto.chordgen.PlayedNote>} value  */
proto.chordgen.Voicing.prototype.setNotesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 2, value);
};


proto.chordgen.Voicing.prototype.clearNotesList = function() {
  this.setNotesList([]);
};


/**
 * optional Tuning tuning = 3;
 * @return {proto.chordgen.Tuning}
 */
proto.chordgen.Voicing.prototype.getTuning = function() {
  return /** @type{proto.chordgen.Tuning} */ (
    jspb.Message.getWrapperField(this, proto.chordgen.Tuning, 3));
};


/** @param {proto.chordgen.Tuning|undefined} value  */
proto.chordgen.Voicing.prototype.setTuning = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.chordgen.Voicing.prototype.clearTuning = function() {
  this.setTuning(undefined);
};


/**
 * Returns whether this field is set.
 * @return{!boolean}
 */
proto.chordgen.Voicing.prototype.hasTuning = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.ScoringInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chordgen.ScoringInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.ScoringInformation.displayName = 'proto.chordgen.ScoringInformation';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.ScoringInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.ScoringInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.ScoringInformation} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.ScoringInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    score: msg.getScore(),
    subscoresMap: (f = msg.getSubscoresMap(true)) ? f.toArray() : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.ScoringInformation}
 */
proto.chordgen.ScoringInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.ScoringInformation;
  return proto.chordgen.ScoringInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.ScoringInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.ScoringInformation}
 */
proto.chordgen.ScoringInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setScore(value);
      break;
    case 2:
      var value = msg.getSubscoresMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readDouble);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.ScoringInformation} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.ScoringInformation.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.ScoringInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.ScoringInformation.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getScore();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = this.getSubscoresMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeDouble);
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.ScoringInformation} The clone.
 */
proto.chordgen.ScoringInformation.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.ScoringInformation} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional double score = 1;
 * @return {number}
 */
proto.chordgen.ScoringInformation.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 1, 0));
};


/** @param {number} value  */
proto.chordgen.ScoringInformation.prototype.setScore = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * map<string, double> subscores = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.chordgen.ScoringInformation.prototype.getSubscoresMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.Settings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chordgen.Settings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.Settings.displayName = 'proto.chordgen.Settings';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.Settings.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.Settings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.Settings} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.Settings.toObject = function(includeInstance, msg) {
  var f, obj = {
    tuning: msg.getTuning(),
    capo: msg.getCapo(),
    targetposition: msg.getTargetposition(),
    targetfret: msg.getTargetfret(),
    disablededup: msg.getDisablededup(),
    disablebars: msg.getDisablebars()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.Settings}
 */
proto.chordgen.Settings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.Settings;
  return proto.chordgen.Settings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.Settings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.Settings}
 */
proto.chordgen.Settings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.chordgen.TuningId} */ (reader.readEnum());
      msg.setTuning(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCapo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetposition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetfret(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisablededup(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisablebars(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.Settings} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Settings.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.Settings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Settings.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getTuning();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = this.getCapo();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = this.getTargetposition();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = this.getTargetfret();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = this.getDisablededup();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = this.getDisablebars();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.Settings} The clone.
 */
proto.chordgen.Settings.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.Settings} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional TuningId tuning = 1;
 * @return {!proto.chordgen.TuningId}
 */
proto.chordgen.Settings.prototype.getTuning = function() {
  return /** @type {!proto.chordgen.TuningId} */ (jspb.Message.getFieldProto3(this, 1, 0));
};


/** @param {!proto.chordgen.TuningId} value  */
proto.chordgen.Settings.prototype.setTuning = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional int32 capo = 2;
 * @return {number}
 */
proto.chordgen.Settings.prototype.getCapo = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 2, 0));
};


/** @param {number} value  */
proto.chordgen.Settings.prototype.setCapo = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * optional int32 targetPosition = 3;
 * @return {number}
 */
proto.chordgen.Settings.prototype.getTargetposition = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 3, 0));
};


/** @param {number} value  */
proto.chordgen.Settings.prototype.setTargetposition = function(value) {
  jspb.Message.setField(this, 3, value);
};


/**
 * optional int32 targetFret = 4;
 * @return {number}
 */
proto.chordgen.Settings.prototype.getTargetfret = function() {
  return /** @type {number} */ (jspb.Message.getFieldProto3(this, 4, 0));
};


/** @param {number} value  */
proto.chordgen.Settings.prototype.setTargetfret = function(value) {
  jspb.Message.setField(this, 4, value);
};


/**
 * optional bool disableDedup = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.chordgen.Settings.prototype.getDisablededup = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldProto3(this, 5, false));
};


/** @param {boolean} value  */
proto.chordgen.Settings.prototype.setDisablededup = function(value) {
  jspb.Message.setField(this, 5, value);
};


/**
 * optional bool disableBars = 6;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.chordgen.Settings.prototype.getDisablebars = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldProto3(this, 6, false));
};


/** @param {boolean} value  */
proto.chordgen.Settings.prototype.setDisablebars = function(value) {
  jspb.Message.setField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.NoteList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chordgen.NoteList.repeatedFields_, null);
};
goog.inherits(proto.chordgen.NoteList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.NoteList.displayName = 'proto.chordgen.NoteList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chordgen.NoteList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.NoteList.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.NoteList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.NoteList} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.NoteList.toObject = function(includeInstance, msg) {
  var f, obj = {
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto.chordgen.PlayedNote.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.NoteList}
 */
proto.chordgen.NoteList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.NoteList;
  return proto.chordgen.NoteList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.NoteList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.NoteList}
 */
proto.chordgen.NoteList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chordgen.PlayedNote;
      reader.readMessage(value,proto.chordgen.PlayedNote.deserializeBinaryFromReader);
      msg.getNotesList().push(value);
      msg.setNotesList(msg.getNotesList());
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.NoteList} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.NoteList.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.NoteList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.NoteList.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.chordgen.PlayedNote.serializeBinaryToWriter
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.NoteList} The clone.
 */
proto.chordgen.NoteList.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.NoteList} */ (jspb.Message.cloneMessage(this));
};


/**
 * repeated PlayedNote notes = 1;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.chordgen.PlayedNote>}
 */
proto.chordgen.NoteList.prototype.getNotesList = function() {
  return /** @type{!Array.<!proto.chordgen.PlayedNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chordgen.PlayedNote, 1));
};


/** @param {Array.<!proto.chordgen.PlayedNote>} value  */
proto.chordgen.NoteList.prototype.setNotesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


proto.chordgen.NoteList.prototype.clearNotesList = function() {
  this.setNotesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.VoicingSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.chordgen.VoicingSet.repeatedFields_, null);
};
goog.inherits(proto.chordgen.VoicingSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.VoicingSet.displayName = 'proto.chordgen.VoicingSet';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.chordgen.VoicingSet.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.VoicingSet.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.VoicingSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.VoicingSet} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.VoicingSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    chord: (f = msg.getChord()) && proto.chordgen.Chord.toObject(includeInstance, f),
    tuning: (f = msg.getTuning()) && proto.chordgen.Tuning.toObject(includeInstance, f),
    voicingsList: jspb.Message.toObjectList(msg.getVoicingsList(),
    proto.chordgen.NoteList.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.VoicingSet}
 */
proto.chordgen.VoicingSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.VoicingSet;
  return proto.chordgen.VoicingSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.VoicingSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.VoicingSet}
 */
proto.chordgen.VoicingSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.chordgen.Chord;
      reader.readMessage(value,proto.chordgen.Chord.deserializeBinaryFromReader);
      msg.setChord(value);
      break;
    case 2:
      var value = new proto.chordgen.Tuning;
      reader.readMessage(value,proto.chordgen.Tuning.deserializeBinaryFromReader);
      msg.setTuning(value);
      break;
    case 3:
      var value = new proto.chordgen.NoteList;
      reader.readMessage(value,proto.chordgen.NoteList.deserializeBinaryFromReader);
      msg.getVoicingsList().push(value);
      msg.setVoicingsList(msg.getVoicingsList());
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.VoicingSet} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.VoicingSet.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.VoicingSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.VoicingSet.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getChord();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.chordgen.Chord.serializeBinaryToWriter
    );
  }
  f = this.getTuning();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.chordgen.Tuning.serializeBinaryToWriter
    );
  }
  f = this.getVoicingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.chordgen.NoteList.serializeBinaryToWriter
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.VoicingSet} The clone.
 */
proto.chordgen.VoicingSet.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.VoicingSet} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional Chord chord = 1;
 * @return {proto.chordgen.Chord}
 */
proto.chordgen.VoicingSet.prototype.getChord = function() {
  return /** @type{proto.chordgen.Chord} */ (
    jspb.Message.getWrapperField(this, proto.chordgen.Chord, 1));
};


/** @param {proto.chordgen.Chord|undefined} value  */
proto.chordgen.VoicingSet.prototype.setChord = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.chordgen.VoicingSet.prototype.clearChord = function() {
  this.setChord(undefined);
};


/**
 * Returns whether this field is set.
 * @return{!boolean}
 */
proto.chordgen.VoicingSet.prototype.hasChord = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Tuning tuning = 2;
 * @return {proto.chordgen.Tuning}
 */
proto.chordgen.VoicingSet.prototype.getTuning = function() {
  return /** @type{proto.chordgen.Tuning} */ (
    jspb.Message.getWrapperField(this, proto.chordgen.Tuning, 2));
};


/** @param {proto.chordgen.Tuning|undefined} value  */
proto.chordgen.VoicingSet.prototype.setTuning = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.chordgen.VoicingSet.prototype.clearTuning = function() {
  this.setTuning(undefined);
};


/**
 * Returns whether this field is set.
 * @return{!boolean}
 */
proto.chordgen.VoicingSet.prototype.hasTuning = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated NoteList voicings = 3;
 * If you change this array by adding, removing or replacing elements, or if you
 * replace the array itself, then you must call the setter to update it.
 * @return {!Array.<!proto.chordgen.NoteList>}
 */
proto.chordgen.VoicingSet.prototype.getVoicingsList = function() {
  return /** @type{!Array.<!proto.chordgen.NoteList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.chordgen.NoteList, 3));
};


/** @param {Array.<!proto.chordgen.NoteList>} value  */
proto.chordgen.VoicingSet.prototype.setVoicingsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 3, value);
};


proto.chordgen.VoicingSet.prototype.clearVoicingsList = function() {
  this.setVoicingsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.AllChordVoicings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chordgen.AllChordVoicings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.AllChordVoicings.displayName = 'proto.chordgen.AllChordVoicings';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.AllChordVoicings.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.AllChordVoicings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.AllChordVoicings} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.AllChordVoicings.toObject = function(includeInstance, msg) {
  var f, obj = {
    voicingsMap: (f = msg.getVoicingsMap(true)) ? f.toArray() : [],
    aliasesMap: (f = msg.getAliasesMap(true)) ? f.toArray() : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.AllChordVoicings}
 */
proto.chordgen.AllChordVoicings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.AllChordVoicings;
  return proto.chordgen.AllChordVoicings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.AllChordVoicings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.AllChordVoicings}
 */
proto.chordgen.AllChordVoicings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getVoicingsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.chordgen.VoicingSet.deserializeBinaryFromReader);
         });
      break;
    case 2:
      var value = msg.getAliasesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.AllChordVoicings} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.AllChordVoicings.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.AllChordVoicings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.AllChordVoicings.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getVoicingsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.chordgen.VoicingSet.serializeBinaryToWriter);
  }
  f = this.getAliasesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.AllChordVoicings} The clone.
 */
proto.chordgen.AllChordVoicings.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.AllChordVoicings} */ (jspb.Message.cloneMessage(this));
};


/**
 * map<string, VoicingSet> voicings = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.chordgen.VoicingSet>}
 */
proto.chordgen.AllChordVoicings.prototype.getVoicingsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.chordgen.VoicingSet>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.chordgen.VoicingSet));
};


/**
 * map<string, string> aliases = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.chordgen.AllChordVoicings.prototype.getAliasesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.chordgen.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.chordgen.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.chordgen.Error.displayName = 'proto.chordgen.Error';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.chordgen.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.chordgen.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.chordgen.Error} msg The msg instance to transform.
 * @return {!Object}
 */
proto.chordgen.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: msg.getMessage(),
    code: msg.getCode()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.chordgen.Error}
 */
proto.chordgen.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.chordgen.Error;
  return proto.chordgen.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.chordgen.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.chordgen.Error}
 */
proto.chordgen.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {!proto.chordgen.ErrorCode} */ (reader.readEnum());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Class method variant: serializes the given message to binary data
 * (in protobuf wire format), writing to the given BinaryWriter.
 * @param {!proto.chordgen.Error} message
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Error.serializeBinaryToWriter = function(message, writer) {
  message.serializeBinaryToWriter(writer);
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.chordgen.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  this.serializeBinaryToWriter(writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the message to binary data (in protobuf wire format),
 * writing to the given BinaryWriter.
 * @param {!jspb.BinaryWriter} writer
 */
proto.chordgen.Error.prototype.serializeBinaryToWriter = function (writer) {
  var f = undefined;
  f = this.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = this.getCode();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * Creates a deep clone of this proto. No data is shared with the original.
 * @return {!proto.chordgen.Error} The clone.
 */
proto.chordgen.Error.prototype.cloneMessage = function() {
  return /** @type {!proto.chordgen.Error} */ (jspb.Message.cloneMessage(this));
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.chordgen.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldProto3(this, 1, ""));
};


/** @param {string} value  */
proto.chordgen.Error.prototype.setMessage = function(value) {
  jspb.Message.setField(this, 1, value);
};


/**
 * optional ErrorCode code = 2;
 * @return {!proto.chordgen.ErrorCode}
 */
proto.chordgen.Error.prototype.getCode = function() {
  return /** @type {!proto.chordgen.ErrorCode} */ (jspb.Message.getFieldProto3(this, 2, 0));
};


/** @param {!proto.chordgen.ErrorCode} value  */
proto.chordgen.Error.prototype.setCode = function(value) {
  jspb.Message.setField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.chordgen.NoteType = {
  ROOT: 0,
  THIRD: 1,
  FIFTH: 2,
  SEVENTH: 3,
  EXTENSION: 4,
  BASS: 5,
  MELODY: 6
};

/**
 * @enum {number}
 */
proto.chordgen.ChordFlavor = {
  MAJOR: 0,
  MINOR: 1,
  DIMINISHED: 2,
  AUGMENTED: 3,
  DOMINANT: 4,
  MAJOR_SEVEN: 5,
  SIXTH: 6,
  EXTENSIONS: 20
};

/**
 * @enum {number}
 */
proto.chordgen.TuningId = {
  STANDARD: 0,
  DROP_D: 1,
  UKULELE: 2,
  DOUBLE_DROP_D: 3,
  DADGAD: 4,
  OPEN_D: 5,
  OPEN_E: 6,
  OPEN_G: 7,
  OPEN_A: 8
};

/**
 * @enum {number}
 */
proto.chordgen.ErrorCode = {
  NONE: 0,
  NOTE_PARSE_INVALID: 1,
  NOTE_PARSE_SHARP_FLAT: 2,
  PARSE_EMPTY: 3,
  CHORD_NAME_INVALID: 4,
  CHORD_SUFFIX_INVALID: 5
};

goog.object.extend(exports, proto.chordgen);
