import React, { SyntheticEvent } from 'react';
import { GuitarString, VoicingSet, NoteList, Tuning, PlayedNote, Chord } from "../gen/proto/chordgen_pb";
import VoicingChart from "./VoicingChart";
import nullthrows from '../util/nullthrows';
import { playChord } from '../audio/synth';
import ChordOverview from './ChordOverview';

interface VoicingProps {
  notes: NoteList,
  tuning: Tuning,
  chord: Chord,
}
const initialState = {
  stringsPlaying: [] as GuitarString[],
};
type VoicingState = typeof initialState;
class Voicing extends React.Component<VoicingProps, VoicingState> {
  readonly state = initialState;

  render() {
    const play = (event: SyntheticEvent) => {
      playChord(
        this.props.notes.getNotesList(),
        note => this.addPlayingNote(note),
      );
      event.preventDefault();
    }
    return (
      <div className="voicing-result" >
        <VoicingChart
          chord={this.props.chord}
          tuning={this.props.tuning}
          notes={this.props.notes.getNotesList()}
          stringsPlaying={this.state.stringsPlaying}
        />
        <div className="voicing-result-actions">
          <a className="voicing-result-action button" href="#" onClick={play}>
            <i className="fas fa-volume-up voicing-result-action-icon" />
            Play
          </a>
        </div>
      </div>
    );
  }
  private addPlayingNote(note: PlayedNote) {
    const playedString = nullthrows(note.getString());
    const playing = this.state.stringsPlaying.slice();
    playing.push(playedString);
    this.setState({ stringsPlaying: playing });
    setTimeout(
      () => {
        const removed = this.state.stringsPlaying.slice(1);
        this.setState({ stringsPlaying: removed });
      },
      350
    );
  }
}

interface Props {
  voicings: VoicingSet,
}
export default function VoicingResults(props: Props) {
  const voicings = props.voicings.getVoicingsList().slice().reverse();
  const chord = nullthrows(props.voicings.getChord());
  const charts = voicings.map((voicing, ix) => {
    return (
      <Voicing
        key={ix}
        chord={chord}
        notes={voicing}
        tuning={nullthrows(props.voicings.getTuning())}
      />
    );
  }
  );
  return (
    <div>
      <ChordOverview chord={chord} />
      <div className="voicing-results">
        {charts}
      </div>
    </div>
  );
}