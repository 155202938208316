import { Settings, TuningId } from "../gen/proto/chordgen_pb";

export class ParseError extends Error { }

interface TokenTake {
  take: number,
}
type TokenList = string[];
type TokenAction = (settings: Settings, tokens: string[]) => TokenTake;
interface TokenRule {
  matches: TokenList[],
  action: TokenAction,
}

function tokenListMatch(list: TokenList, tokens: string[]): TokenTake | null {
  for (let i = 0; i < list.length; i++) {
    if (i >= tokens.length || tokens[i] !== list[i]) {
      return null;
    }
  }
  return take(list.length);
}

function ruleMatchesPrefix(rule: TokenRule, tokens: string[]): TokenTake | null {
  for (const list of rule.matches) {
    const take = tokenListMatch(list, tokens);
    if (take != null) {
      return take;
    }
  }
  return null;
}

function take(n: number) {
  return { take: n };
}

function rule(matches: string[], action: TokenAction): TokenRule {
  return {
    matches: [matches],
    action,
  };
}
function multiRule(matches: TokenList[], action: TokenAction) {
  return {
    matches,
    action,
  }
}
function takeNumber(tokens: string[], message: string) {
  if (tokens.length === 0) {
    throw new ParseError(`Looking for a number but the input ended, ${message}`)
  }
  const num = Number(tokens[0]);
  if (isNaN(num)) {
    throw new ParseError(`Looking for a number but got ${tokens[0]}, ${message}`)
  }
  return num;
}

const rules = [
  multiRule(
    [
      ["ukulele"],
      // because spelling is hard
      ["ukelele"],
      ["uke"]
    ],
    (settings, tokens) => {
      settings.setTuning(TuningId.UKULELE)
      if (tokens[0] === 'tuning') {
        return take(1);
      }
      return take(0);
    }
  ),
  multiRule(
    [
      ["dropd"],
      ["drop", "d"],
    ],
    (settings, tokens) => {
      settings.setTuning(TuningId.DROP_D)
      if (tokens[0] === 'tuning') {
        return take(1);
      }
      return take(0);
    }
  ),
  multiRule(
    [
      ["dadgad"],
    ],
    (settings, tokens) => {
      settings.setTuning(TuningId.DADGAD)
      if (tokens[0] === 'tuning') {
        return take(1);
      }
      return take(0);
    }
  ),
  multiRule(
    [
      ["open", "g"],
      ["openg"],
    ],
    (settings, tokens) => {
      settings.setTuning(TuningId.OPEN_G)
      if (tokens[0] === 'tuning') {
        return take(1);
      }
      return take(0);
    }
  ),
  multiRule(
    [
      ["open", "a"],
      ["opena"],
    ],
    (settings, tokens) => {
      settings.setTuning(TuningId.OPEN_A)
      if (tokens[0] === 'tuning') {
        return take(1);
      }
      return take(0);
    }
  ),
  multiRule(
    [
      ["double", "drop", "d"],
    ],
    (settings, tokens) => {
      settings.setTuning(TuningId.DOUBLE_DROP_D)
      if (tokens[0] === 'tuning') {
        return take(1);
      }
      return take(0);
    }
  ),
  multiRule(
    [
      ["opend"],
      ["open", "d"],
    ],
    (settings, tokens) => {
      settings.setTuning(TuningId.OPEN_D)
      if (tokens[0] === 'tuning') {
        return take(1);
      }
      return take(0);
    }
  ),
  multiRule(
    [
      ["opene"],
      ["open", "e"],
    ],
    (settings, tokens) => {
      settings.setTuning(TuningId.OPEN_E)
      if (tokens[0] === 'tuning') {
        return take(1);
      }
      return take(0);
    }
  ),
  multiRule(
    [
      ["no", "bars"],
      ["no", "bar"],
    ],
    settings => {
      settings.setDisablebars(true);
      return take(0);
    }
  ),
  multiRule(
    [
      ["near", "fret"],
      ["fret"],
      ["around", "fret"],
    ],
    (settings, tokens) => {
      settings.setTargetfret(takeNumber(tokens, "like 'around fret 3'"));
      return take(1);
    }
  ),
  rule(
    [
      "capo"
    ],
    (settings, tokens) => {
      settings.setCapo(takeNumber(tokens, "like 'capo 5'"))
      return take(1);
    }
  )
]

interface ChordAndSettings {
  chordName: string,
  settings: Settings,
}
const fillers = [
  "with",
  "on",
  "in",
  "",
];
export default function parseChordAndSettings(input: string): ChordAndSettings{
  let tokens = input.split(/\s+/);
  const settings = new Settings();
  if (tokens.length === 0) {
    return {
      chordName: '',
      settings,
    }
  }

  const chordName = tokens[0];

  tokens = tokens
    .slice(1)
    .map(token => token.toLowerCase())
    .filter(token => !fillers.includes(token));

  while (tokens.length > 0) {
    let anyMatch = false;
    for (const rule of rules) {
      const take = ruleMatchesPrefix(rule, tokens);
      if (take !== null) {
        tokens = tokens.slice(take.take);
        const actionTake = rule.action(settings, tokens);
        tokens = tokens.slice(actionTake.take);

        anyMatch = true;
        break;
      }
    }

    if (!anyMatch) {
      throw new ParseError(`Didn't understand a word: ${tokens[0]}`);
    }
  }

  return {
    chordName,
    settings,
  };
}