type DebounceFn = (...args: any[]) => void;

export default function debounce(fn: DebounceFn, bounceMs: number) {
  let lastTimeout = null as NodeJS.Timeout | null;

  return (...args: any[]) => {
    if (lastTimeout != null) {
      clearTimeout(lastTimeout);
    }

    const invoke = () => {
      fn(...args);
    };
    lastTimeout = setTimeout(invoke, bounceMs);
  };
}