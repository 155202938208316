import React from 'react';
import { Chord, ChordFlavor, Note, NoteType } from '../gen/proto/chordgen_pb';
import nullthrows from '../util/nullthrows';
import '../css/ChordOverview.css';

const COLOR_BY_INTERVAL = {
  // Triad colors
  1: '#f0646b',
  3: '#8fc4ab',
  5: '#6eaaf6',
  // Sevens
  7: '#dacc99',
  // Extensions
  2: '#dcace1',
  4: '#e1b17e',
  6: '#b775d4',
  // Other
  bass: '#8891a7',
  melody: '#bc6666',
} as Record<string, string>;

export function getChordAccentColor(note: Note) {
  if (note.getType() === NoteType.BASS) {
    return COLOR_BY_INTERVAL['bass'];
  } else if (note.getType() === NoteType.MELODY) {
    return COLOR_BY_INTERVAL['melody'];
  }
  const interval = nullthrows(note.getInterval()).getRange();
  return COLOR_BY_INTERVAL[interval];
}

function flavorDescription(flavor: ChordFlavor) {
  switch (flavor) {
    case ChordFlavor.MAJOR:
      return 'major';
    case ChordFlavor.MINOR:
      return 'minor';
    case ChordFlavor.DIMINISHED:
      return 'diminished';
    case ChordFlavor.AUGMENTED:
      return 'augmented';

    case ChordFlavor.SIXTH:
      return 'six';
    case ChordFlavor.DOMINANT:
      return 'seven';
    case ChordFlavor.MAJOR_SEVEN:
      return 'major seven';

    default:
      throw new Error(`unhandled chord flavor: ${flavor.toString()}`);
  }
}
function combinationFlavor(primary: ChordFlavor, secondary: ChordFlavor) {
  if (primary == ChordFlavor.MAJOR && secondary == ChordFlavor.DOMINANT) {
    return 'dominant';
  } else if (primary == ChordFlavor.MAJOR) {
    return flavorDescription(secondary);
  }
  return `${flavorDescription(primary)} ${flavorDescription(secondary)}`;
}

function classNameWithFlats(name: string, baseClassName: string) {
  let nameClass = baseClassName;
  if (name.indexOf("♭") >= 0) {
    nameClass += " chord-view-name-flats";
  }
  return nameClass;
}

function ChordDescription({ chord }: { chord: Chord }) {
  const flavors = chord.getFlavorsList();

  let description = null;
  if (flavors.length === 1) {
    description = <span className="chord-view-desc">{flavorDescription(flavors[0])}</span>;
  } else if (flavors.length >= 1 && flavors[1] !== ChordFlavor.EXTENSIONS) {
    description = <span className="chord-view-desc">
      {combinationFlavor(flavors[0], flavors[1])}
    </span>;
  }
  let extensions = null;
  if (flavors.findIndex(flavor => flavor == ChordFlavor.EXTENSIONS) >= 0) {
    extensions = (
      <span className="chord-view-extensions"> (with extensions) </span>
    )
  }

  const name = nullthrows(
    chord.getNotesList().find(note => note.getType() === NoteType.ROOT)
  ).getName();
  return (
    <span>
      <span className={classNameWithFlats(name, "chord-view-name")}>{name}{' '}</span>
      {description}
      {extensions}
    </span>
  );
}

function chordNoteDescription(note: Note, hasSeven: boolean) {
  if (note.getType() === NoteType.BASS) {
    return "Bass";
  } else if (note.getType() === NoteType.ROOT) {
    return "Root";
  } else if (note.getType() === NoteType.MELODY) {
    return "Top";
  }
  let interval = nullthrows(note.getInterval()).getRange();
  if (interval == 3) {
    return "3rd";
  }
  // Label lower intervals as above the octave, e.g. 2 => 9
  if (hasSeven && interval % 2 == 0) {
    interval += 7;
  }
  if (interval == 2) {
    return "2nd";
  }
  return interval + "th";
}

function ChordNotes({ notes }: { notes: Note[] }) {
  const hasSeven = notes.findIndex(note => note.getType() == NoteType.SEVENTH) >= 0;
  const renderedNotes = notes.map((note, idx) => {
    const color = getChordAccentColor(note);
    const style = { borderColor: color };
    return (
      <div key={idx} className="chord-view-note-outer">
        <div className={classNameWithFlats(note.getName(), "chord-view-note")} style={style}>
          {note.getName()}
        </div>
        <div className="chord-view-note-desc">
          {chordNoteDescription(note, hasSeven)}
        </div>
      </div>
    )
  });
  return (
    <div className="chord-view-notes-outer">
      <div className="chord-view-notes">
        {renderedNotes}
      </div>
    </div>
  )
}

interface Props {
  chord: Chord,
}
export default function ChordOverview(props: Props) {
  return (
    <div className="chord-view">
      <div className="chord-view-inner">
        <div className="chord-view-row">
          <span className="chord-view-label">Chord</span>
          <ChordDescription chord={props.chord} />
        </div>
        <div className="chord-view-row">
          <span className="chord-view-label">Notes</span>
          <ChordNotes notes={props.chord.getNotesList()} />
        </div>
      </div>
    </div>
  );
}