import React, { Component } from 'react';

import '../css/ChordSearch.css';
import debounce from '../util/debounce';

const initialState = {
  input: '',
};
type State = Readonly<typeof initialState>;
interface Props {
  onSubmit: (input: string) => void,
}

export default class ChordSearch extends Component<Props, State>{
  readonly state: State = initialState;

  changeHandler = debounce(() => this.props.onSubmit(this.state.input), 1000);

  render() {
    return (
      <div>
        <div id="search-description">
          What chord do you want to play?
        </div>
        <div id="search">
          <input
            type="text"
            id="search-input"
            name="search-input"
            placeholder='Try "G" or "C#m7"'
            value={this.state.input}
            onChange={event => this.updateInput(event.target.value)}
            onKeyPress={event => this.handleKeypress(event)}
          />
        </div>
      </div >
    );
  }

  private updateInput(input: string) {
    // Force the first letter to be uppercase
    const fixedInput = input.slice(0, 1).toUpperCase() + input.slice(1);
    this.setState({
      input: fixedInput,
    });
    this.changeHandler();
  }
  private handleKeypress(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      this.props.onSubmit(this.state.input);
    }
  }
}
