import React from 'react';
import { GuitarString, PlayedNote, Tuning, Chord, NoteType } from "../gen/proto/chordgen_pb";
import nullthrows from '../util/nullthrows';

import '../css/VoicingChart.css';
import { getChordAccentColor } from './ChordOverview';

const START_HEIGHT_PCT = 9;
const END_HEIGHT_PCT = 91;
const START_WIDTH_PCT = 1;
const END_WIDTH_PCT = 99;

const DOT_FRETS = {
  3: 1,
  5: 1,
  7: 1,
  9: 1,
  12: 2,
  15: 1,
  17: 1,
  19: 1,
  21: 1,
  24: 2,
} as Record<number, number>;

function colorsByString(notesByString: Record<number, PlayedNote>, chord: Chord) {
  const colors = {} as Record<number, string>;
  const sortedByPitch = Object.values(notesByString).map(note => note.getPitch());
  sortedByPitch.sort((a, b) => a - b);
  const lowest = sortedByPitch[0];
  const highest = sortedByPitch[sortedByPitch.length - 1];

  Object.keys(notesByString).forEach(string => {
    const played = notesByString[Number(string)];
    const note = nullthrows(played.getNote());
    let chordNote = chord.getNotesList().find(it =>
      it.getName() === note.getName() &&
      it.getType() !== NoteType.BASS &&
      it.getType() !== NoteType.MELODY
    );
    const bass = chord.getNotesList().find(it => it.getType() === NoteType.BASS);
    const melody = chord.getNotesList().find(it => it.getType() === NoteType.MELODY);
    if (played.getPitch() === lowest && bass != null) {
      chordNote = bass;
    } else if (played.getPitch() === highest && melody != null) {
      chordNote = melody;
    }

    if (chordNote == null) {
      // Hack until API doesn't return bass notes/melody notes in the
      // middle of the chord
      chordNote = chord.getNotesList().find(it => it.getName() === note.getName());
    }
    colors[Number(string)] = getChordAccentColor(nullthrows(chordNote));
  });
  return colors;
}

function stringHeights(strings: GuitarString[]): Record<number, number> {
  const heightPerString = (END_HEIGHT_PCT - START_HEIGHT_PCT) / (strings.length - 1);

  const heights = {} as Record<string, number>;
  for (let i = 0; i < strings.length - 1; ++i) {
    heights[strings[strings.length - 1 - i].getIndex()] = i * heightPerString + START_HEIGHT_PCT;
  }
  heights[strings[0].getIndex()] = END_HEIGHT_PCT;
  return heights;
}

function fretWidths(notesByString: Record<number, PlayedNote>) {
  const fretWidths = {} as Record<number, number>;
  let fretsUsed = Object.values(notesByString)
    .filter(note => note.getFinger() > 0)
    .map(
      note => note.getFret()
    )
    .sort((a, b) => a - b);
  if (fretsUsed.length === 0) {
    fretsUsed = [0];
  }

  const minFret = Math.max(0, fretsUsed[0] - 2);
  let maxFret = fretsUsed[fretsUsed.length - 1] + 1;
  // Make spread at least 5
  const fretSpread = Math.max(4, maxFret - minFret);
  maxFret = minFret + fretSpread;

  const widthPerFret = (END_WIDTH_PCT - START_WIDTH_PCT) / (fretSpread - 1);
  for (let fret = minFret; fret < maxFret; ++fret) {
    fretWidths[fret] = START_WIDTH_PCT + (fret - minFret) * widthPerFret;
  }
  return fretWidths;
}

interface FretboardProps {
  strings: GuitarString[],
  notesByString: Record<number, PlayedNote>,
  colorsByString: Record<number, string>,
  stringsPlaying: GuitarString[],
}

function StringsPanel(props: FretboardProps) {
  const heights = stringHeights(props.strings);
  const textStrings = props.strings.map(string =>
    <text
      key={string.getIndex()}
      x="25%"
      y={heights[string.getIndex()] + '%'}
      className="voicing-chart-text-label"
    >
      {string.getName().toUpperCase()}
    </text>
  );
  const openStringCircles = Object.keys(props.notesByString).map(
    string => {
      const note = props.notesByString[Number(string)];
      if (note == null || note.getFinger() > 0) {
        return null;
      }
      return (
        <circle
          key={`open-${string}`}
          cx="80%"
          cy={heights[Number(string)] + '%'}
          r="4"
          style={{ stroke: props.colorsByString[Number(string)] }}
          className="voicing-chart-open-circle"
        />
      );
    }
  );

  return (
    <svg className="voicing-chart-label">
      {textStrings}
      {openStringCircles}
    </svg>
  )
}

function getFingerSpans(notes: PlayedNote[], strings: GuitarString[]) {
  const unplayedStrings = strings.filter(string => {
    return !notes.find(note => {
      const noteString = note.getString();
      return noteString != null && noteString.getIndex() == string.getIndex();
    });
  });
  const unplayedIndices = unplayedStrings.map(string => string.getIndex());

  interface FingerSpan {
    min: number,
    max: number,
    fret: number,
    finger: number,
  }
  const spans = {} as Record<number, FingerSpan>;
  for (const note of notes) {
    const string = note.getString();
    if (string == null || note.getFinger() == 0) {
      continue;
    }

    const idx = string.getIndex();
    let span = spans[note.getFinger()];
    if (span == null) {
      span = {
        min: idx,
        max: idx,
        fret: note.getFret(),
        finger: note.getFinger(),
      }
      spans[note.getFinger()] = span;
    } else {
      span.min = Math.min(span.min, idx);
      span.max = Math.max(span.max, idx);
    }
  }
  function splitSpan(span: FingerSpan): FingerSpan[] {
    if (span.max < span.min) {
      return [];
    }
    for (const unplayed of unplayedIndices) {
      if (span.min <= unplayed && unplayed <= span.max) {
        const lo = {
          ...span,
          max: unplayed - 1,
        };
        const hi = {
          ...span,
          min: unplayed + 1,
        };
        return [splitSpan(lo), splitSpan(hi)].flat();
      }
    }
    return [span];
  }

  return Object.values(spans).map(span => splitSpan(span)).flat();
}

function getColoredBarPaths(
  x: number,
  minString: number,
  maxString: number,
  fret: number,
  heights: Record<number, number>,
  notesByString: Record<number, PlayedNote>,
  colorsByString: Record<number, string>
) {
  function getColor(string: number) {
    const onString = notesByString[string];
    if (onString == null || onString.getFret() !== fret) {
      return '#cccccc';
    }
    return colorsByString[string];
  }
  const paths = [] as React.ReactNode[];
  function verticalLines(y1: number, y2: number, color: string) {
    const style = { stroke: color };
    paths.push(
      <line
        key={`bar-${fret}-${paths.length}-l`}
        y1={`${y1}%`}
        y2={`${y2}%`}
        x1={`${x - 3.5}%`}
        x2={`${x - 3.5}%`}
        className="voicing-chart-bar-path"
        style={style}
      />
    );
    paths.push(
      <line
        key={`bar-${fret}-${paths.length}-r`}
        y1={`${y1}%`}
        y2={`${y2}%`}
        x1={`${x + 3.5}%`}
        x2={`${x + 3.5}%`}
        className="voicing-chart-bar-path"
        style={style}
      />
    );
  }
  function curvedEnd(height: number, color: string) {
    const style = { stroke: color };
    paths.push(
      <ellipse
        key={`bar-tip-${fret}-${paths.length}`}
        cx={`${x}%`}
        cy={`${height}%`}
        rx="3.5%"
        ry="5%"
        style={style}
        className="voicing-chart-bar-path"
      />
    );
  }
  for (let string = minString + 1; string < maxString; string++) {
    const from = (heights[string - 1] + heights[string]) / 2;
    const to = (heights[string] + heights[string + 1]) / 2;
    verticalLines(from, to, getColor(string));
  }
  curvedEnd(heights[minString], getColor(minString));
  verticalLines(
    heights[minString],
    (heights[minString] + heights[minString + 1]) / 2,
    getColor(minString)
  );
  curvedEnd(heights[maxString], getColor(maxString));
  verticalLines(
    heights[maxString],
    (heights[maxString] + heights[maxString - 1]) / 2,
    getColor(maxString)
  );
  return paths;
}

function Fretboard(props: FretboardProps) {
  const heights = stringHeights(props.strings);
  const stringLines = props.strings.map(
    string => {
      const isPlaying = props.stringsPlaying.findIndex(
        playing => playing.getIndex() === string.getIndex()
      ) >= 0;

      let className = "voicing-chart-fretboard-line";
      if (isPlaying) {
        className += " voicing-chart-string-playing";
      }
      return (
        <line
          key={`string-${string.getIndex()}`}
          x1={`${START_WIDTH_PCT}%`}
          x2={`${END_WIDTH_PCT}%`}
          y1={`${heights[string.getIndex()]}%`}
          y2={`${heights[string.getIndex()]}%`}
          className={className}
        />
      );
    }
  );

  const lines = [] as React.ReactNode[];
  function appendLine(x: number) {
    lines.push(
      <line
        key={"fret-" + x}
        x1={`${x}%`}
        x2={`${x}%`}
        y1={`${START_HEIGHT_PCT}%`}
        y2={`${END_HEIGHT_PCT}%`}
        className="voicing-chart-fretboard-line"
      />
    )
  }
  const widthsByFret = fretWidths(props.notesByString);
  if (widthsByFret[0] != null) {
    // Draw another line if we're at the top of the fretboard
    appendLine(START_WIDTH_PCT + 1);
  }
  Object.values(widthsByFret).forEach(width =>
    appendLine(width)
  );

  const fingerSpans = getFingerSpans(
    Object.values(props.notesByString),
    props.strings,
  );
  const noteCircles = fingerSpans.map(span => {
    const fret = span.fret;
    const finger = span.finger;

    const x = (widthsByFret[fret] + widthsByFret[fret - 1]) / 2;
    const minString = props.strings[span.min];
    const maxString = props.strings[span.max];
    const labelHeight = (heights[minString.getIndex()] + heights[maxString.getIndex()]) / 2;
    const label = (
      <text
        key={`label-${finger}-${minString}`}
        x={`${x}%`}
        y={`${labelHeight}%`}
        className="voicing-chart-text-label"
      >
        {finger}
      </text>
    );

    const elems = [] as React.ReactNode[];
    if (minString == maxString) {
      elems.push(
        <circle
          key={`finger-${minString.getIndex()}-${fret}`}
          cx={`${x}%`}
          cy={`${heights[minString.getIndex()]}%`}
          r="11"
          style={{ stroke: props.colorsByString[minString.getIndex()] }}
          className="voicing-chart-open-circle"
        />
      );
    } else {
      const paths = getColoredBarPaths(
        x,
        minString.getIndex(),
        maxString.getIndex(),
        fret,
        heights,
        props.notesByString,
        props.colorsByString
      );
      for (const path of paths) {
        elems.push(path);
      }
      elems.push(
        <rect
          key={`finger-${minString}-${maxString}`}
          y={`${heights[maxString.getIndex()] - 5}%`}
          height={`${heights[minString.getIndex()] - heights[maxString.getIndex()] + 10}%`}
          x={`${x - 3.5}%`}
          rx="5%"
          ry="5%"
          width="7%"
          className="voicing-chart-bar-rect"
        />
      );
    }
    // Label goes on top, so comes last
    elems.push(label);
    return elems;
  }).flat();

  return (
    <svg>
      {stringLines}
      {lines}
      {noteCircles}
    </svg>
  )
}

function FretLabelPanel(props: FretboardProps) {
  const widthsByFret = fretWidths(props.notesByString);
  // Take all but the first fret to label
  const frets = Object.keys(widthsByFret)
    .map(fret => Number(fret))
    .sort((a, b) => a - b)
    .slice(1);

  function FretDot({ x }: { x: number }) {
    return (
      <circle cx={`${x}%`} cy="85%" r="1" className="voicing-chart-fret-dot" />
    );
  }

  const labels = frets.map(fret => {
    const x = (widthsByFret[fret] + widthsByFret[fret - 1]) / 2;
    const labels = [
      <text key={'label-' + x} x={`${x}%`} y="45%" className="voicing-chart-text-label">
        {fret}
      </text>
    ];
    if (DOT_FRETS[fret] == 1) {
      labels.push(<FretDot key={'dot-' + x} x={x} />);
    } else if (DOT_FRETS[fret] == 2) {
      labels.push(<FretDot key={'dot1-' + x} x={x - 1} />);
      labels.push(<FretDot key={'dot2-' + x} x={x + 1} />);
    }
    return labels;
  }).flat();
  return (
    <svg>
      {labels}
    </svg>
  );
}

interface Props {
  chord: Chord,
  notes: PlayedNote[],
  tuning: Tuning,
  stringsPlaying: GuitarString[],
}
export default function VoicingChart(props: Props) {
  const strings = props.tuning.getStringsList();
  const notesByString = {} as Record<number, PlayedNote>;
  for (const note of props.notes) {
    const string = note.getString();
    if (string == null) {
      continue;
    }
    notesByString[string.getIndex()] = note;
  }
  const colors = colorsByString(notesByString, props.chord);
  const subprops = {
    strings,
    notesByString,
    stringsPlaying: props.stringsPlaying,
    colorsByString: colors,
  };
  return (
    <div className="voicing-chart-parent">
      <div className="voicing-chart-strings voicing-chart-sides">
        <StringsPanel {...subprops} />
      </div>
      <div className="voicing-chart-center">
        <div className="voicing-chart-fretboard">
          <Fretboard {...subprops} />
        </div>
        <div className="voicing-chart-fret-labels">
          <FretLabelPanel {...subprops} />
        </div>
      </div>
    </div>
  );
}